import ReactMarkdown from "react-markdown"
import type { Options } from "react-markdown"

type MdProps = Options
export default function Md({
  children,
  components = {
    p: 'div'
  },
  ...props
}: MdProps) {
  return <ReactMarkdown
    {...props}
    components={components}
  >{children}</ReactMarkdown>
}